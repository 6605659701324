<template>
    <div class="scrolllayout"  @click="handleswiperchoose">
        <div :class="swiper1[0].swiperclass==0?'noswiper':'swiper'" :style="'z-index:'+swiper1[0].indexz" >
            <!--                    背景图-->
            <img class="bac"
                 :src="swiper1[0].bac" :style="'--i:'+((screenY/screenX)/(1080/1920)<=1?1:(screenY/screenX)/(1080/1920)>=1.2?1.2:(screenY/screenX)/(1080/1920))" alt="">


            <!--                    遮罩以上-->
<!--          <div class="myshadow">-->
<!--            <div class="myshadow-1"></div>-->
<!--            <div class="myshadow-2"></div>-->
<!--            <div class="myshadow-3"></div>-->
<!--            <div class="myshadow-4"></div>-->
<!--          </div>-->

          <div class="shadow" style="background-color: rgba(0,0,0,0.3)">
            <div class="opchange"  :style="isShow==0?'opacity:1;transition-delay: 0.5s;':'opacity:0;transition-delay: 0s;'">
              <div class="shadow-swiper1-top" >
                <div class="shadow-swiper1-top-top">
                  宇宙中的奇妙旅程 那就是对未知的探索
                </div>
                <div class="shadow-swiper1-top-bottom">
                  即刻启程
                </div>
              </div>
            </div>

            </div>
        </div>
    </div>

</template>

<script>


    import s1 from "../img/xhhc1.png";


    export default {

        data(){
            return{
              screenX:0,
              screenY:0,
              // 1920 1080


              isShow:-1,
                //轮播到哪一页，0为第一页，1为第二页，2为第三页，3为第四页，-1为跳转上一页路由，4为跳转下一页路由
                swiperchoose: 0,
                //记录变化前的swiperchoose
                oddswiperchoose:0,


                //界面宽度的一半
                resizeX:0,


                //节流，阻止事件
                preventcontact:0,


                //轮播
                swiper1: [
                    {
                        //背景图
                        bac: s1,
                        //高度
                        indexz:99,
                        //判断左移还是右移的class,1为左移，-1为右移，0为进入页面不动的默认情况
                        swiperclass:0,
                        diybarposition:1,
                    },
                ],

                videostyle:{
                    transform:'translateX(-50%) translateY(-50%) scale(1)',
                    opacity:1,
                    videoopacity:0,
                    imgindexz:99,
                    videoindexz:0,
                },

                textstyle:{
                    opacity:1,
                },

                datachoose:0,

                // routerindex:0,
            }
        },
        props:{
            componentData:{
                default:0,
                type:Number,
            }
        },
        created() {
            //监听鼠标滚动，500毫秒目的是为了等上一次滚动结束

            setTimeout(()=>{
                window.addEventListener('mousewheel',this.handlemouse)
            },500)
            //监听网页宽度
            window.addEventListener('resize',this.resize)
            this.resize()

            //监听按键
            setTimeout(()=>{
                window.addEventListener('keydown',this.keydown)
            },500)





          this.isShow  = -1
          setTimeout(()=>{
            this.isShow = this.swiperchoose
          },100)
        },
        beforeUnmount() {
            // this.diybarData.beforedetroy = false

        },
        watch:{
            swiperchoose:function (e) {

              this.isShow = e

                if(e>0){
                    this.handlerouter('/home')
                    return
                }else if(e<0){
                  this.handlerouter({'path':this.$store.state.routerlist[this.$route.meta.index-1].router,'query':{'target':2}})
                  return;
                }
            }
        },
        methods: {
            //点击鼠标时触发,点击左边界面右移下一页，点击右边界面左移上一页
            handleswiperchoose(e) {
              //监听是否全屏
              let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement
              if(!isFullScreen){
                return;
              }
                if(this.preventcontact){
                    return
                }
                this.preventcontact=1
                let X = e.clientX
                // if(this.componentData){
                //     X = e.clientY
                // }
                if(X>=this.resizeX){
                    this.swiperchoose++
                }else{
                    this.swiperchoose--
                }
                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            //点击视频菜单事件
            handledatachoose(e){
                if(this.datachoose==e){
                    return
                }
                this.videostyle.opacity= 0.2
                this.videostyle.videoopacity = 0
                this.videostyle.transform = 'translateX(-50%) translateY(-50%) scale(0)'
                this.videostyle.imgindexz = 0
                this.textstyle.opacity = 0.2
                this.videostyle.videoindexz = 0
                setTimeout(()=>{
                    this.datachoose=e
                    this.videostyle.opacity= 1
                    this.videostyle.transform = 'translateX(-50%) translateY(-50%) scale(1)'
                    this.videostyle.imgindexz = 99
                    this.textstyle.opacity = 1
                    this.$refs.myvideo.currentTime = 0
                },500)
            },
            handlediybar(e){
                if(e.target==this.swiperchoose){
                    return
                }
                this.swiperchoose=e.target
                this.diybarData.targetleft = 30 + 50*e.target
            },
            //监听鼠标滚动事件,上滚回退上一张，下滚继续下一张
            handlemouse(e){

                if(this.preventcontact){
                    return;
                }
                this.preventcontact=1
                if(e.deltaY>0){
                    this.swiperchoose++

                }else{

                    this.swiperchoose--


                }

                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            },
            resize(){


              this.screenX = document.body.clientWidth
              this.screenY = document.body.clientHeight
                if(this.componentData){
                    this.resizeX = this.componentData/2
                    return
                }
                this.resizeX = document.body.clientWidth/2
            },
            //跳转路由
            handlerouter(e){
                if(window.location.pathname==e){
                    return
                }
                this.$router.push(e)
            },
            //监听键盘
            keydown(e){
                if(this.preventcontact){
                    return
                }
                this.preventcontact=1
                if(e.keyCode==39){
                    this.swiperchoose++
                }else if(e.keyCode==37){
                    this.swiperchoose--
                }else if(e.keyCode==38){
                    this.swiperchoose=-1
                }else if(e.keyCode==40){
                    this.swiperchoose=4
                }
                setTimeout(()=>{
                    this.preventcontact=0
                },500)
            }


        }
    }
</script>

<style scoped lang="scss">

//.shadow-swiper1-top{
//  transition: 0.3s;
//}

    .swiper{
        position: absolute;
        left: 2%;
        right: 0;
        top: 50%;
    }
    .swiper-unit{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: var(--b-t-size);
        position: absolute;
        width: 675px;
        animation-name: turnleft;
        animation-duration: 16s;
        animation-iteration-count:infinite;
        left: 100%;
        transform: translateY(-50%);
        right: 0;
    }

    .swiper-unit-left{
        width: 158px;
        height: 8px;
        background: var(--s-color1);
        margin-right: 25px;
    }



    .swiper-unit-right-unit{
        background: linear-gradient(to left, var(--s-color1), #f2e6cd 80%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .swiper-unit-right-unit:first-of-type{
        background: linear-gradient(to left, var(--s-color1), #f2e6cd 95%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bottom{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 13%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .more{
        margin-left: 2%;
        text-decoration: none;
        color: rgba(255,255,255,0.9);
        font-size: var(--s-t-size);
        background-image: linear-gradient(to bottom,rgba(0,0,0,0.1),rgba(0,123,211,0.4));
        padding: 0 62px;
        height: 63px;
        line-height: 63px;
    }
    .bottom-right{
        display: flex;
        flex-direction: row;
        width: 413px;
        font-size: var(--s-c-size);
        color: rgba(255,255,255,0.9);
        margin-right: 8%;
    }
    .bottom-right-left{
        background: rgba(255,255,255,0.9);
        width: 1.5px;
        height: 40px;
        margin-right: 34px;
        margin-top: 7px;
    }
    .swiper1{
        line-height: 2;
        position: relative;
        width: 100%;
    }
    .swiper1-unit{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        animation-name: turnup;
        animation-duration: 16s;
        animation-iteration-count:infinite;
        opacity: 0;
    }
    @keyframes turnleft {
        0%{
            left: 100%;
        }
        20%{
            left: 0;
        }
        40%{
            left: 0;
        }
        50%{
            left: -100%;
        }
        100%{
            left: -100%;
        }
    }
    @keyframes turnup {
        0%{
            opacity: 0;
        }
        5%{
            opacity: 1;
        }
        45%{
            opacity: 1;
        }
        50%{
            opacity: 0;
        }
        100%{
            opacity: 0;
        }
    }
    .shadow-swiper1-top{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 28px;
        color: rgba(255,255,255,0.9);
        width: 100%;
    }
    .shadow-swiper1-top-bottom{
        margin-top: 27px;
    }

    .bac{
        animation-name: rotation;
        animation-duration: 100s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform: rotateZ(0deg) scaleY(calc(3*var(--i))) scaleX(calc(2*var(--i)));
    }

    @keyframes rotation {
        0%{
            transform:rotateZ(0deg)   scaleY(calc(3*var(--i))) scaleX(calc(2*var(--i)));
        }
        100%{
            transform:rotateZ(360deg)   scaleY(calc(3*var(--i))) scaleX(calc(2*var(--i)));
        }
    }
    .myshadow{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 199;
    }
    .myshadow-1{
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(135deg,rgba(0,0,0,1) 30%,rgba(0,0,0,0) 30%,rgba(0,0,0,0) 40%,rgba(0,0,0,1) 40%,rgba(0,0,0,1) 50%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 60%);
      background-size:100% 100% ;
    }
</style>